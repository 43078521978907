// var price = (val) => new Intl.NumberFormat("es-CL").format(val);
// taken from http://stackoverflow.com/a/9318724
Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
  var n = this,
          decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
          decSeparator = decSeparator == undefined ? "." : decSeparator,
          thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
          sign = n < 0 ? "-" : "",
          i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
          j = (j = i.length) > 3 ? j % 3 : 0;
  return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
};

var Actions = Reflux.createActions([
    'contract',
    'showModalContract'
]);

class PlansStore extends Reflux.Store {
    constructor(props) {
        super(props);
        this.listenables = [Actions];
        this.state = {
            modalContract:{
                showModal:false
            },
            plans: [{
                id:1,
                space: "100MB",
                db: 0,
                mail: 1,
                transfer: "Tráfico ilimitado",
                ftp: 1,
                alias: 0,
                support: "Soporte 24/7",
                price: 9990
            }, {
                id:2,
                space: "500MB",
                db: 0,
                mail: 1,
                transfer: "Tráfico ilimitado",
                ftp: 1,
                alias: 0,
                support: "Soporte 24/7",
                price: 12990
            }, {
                id:3,
                space: "1GB",
                db: 1,
                mail: 10,
                transfer: "Tráfico ilimitado",
                ftp: 1,
                alias: 1,
                support: "Soporte 24/7",
                price: 24990,
                recommended:true
            }, {
                id:4,
                space: "2GB",
                db: 2,
                mail: 20,
                transfer: "Tráfico ilimitado",
                ftp: 2,
                alias: 2,
                support: "Soporte 24/7",
                price: 45990
            }, {
                id:5,
                space: "3GB",
                db: 3,
                mail: 30,
                transfer: "Tráfico ilimitado",
                ftp: 3,
                alias: 3,
                support: "Soporte 24/7",
                price: 54990
            }, {
                id:6,
                space: "5GB",
                db: 5,
                mail: 50,
                transfer: "Tráfico ilimitado",
                ftp: 5,
                alias: 5,
                support: "Soporte 24/7",
                price: 74990
            }]
        };
    }
    onContract(id){
        // var plan = this.state.plans.find(plan => plan.id==id)
        // console.log('var plan', plan);
    }
    onShowModalContract(id){
        // this.setState({
        //     modalContract: {
        //         showModal:true
        //     }
        // });
        // this.modalContract = $('#modalContract');
        // this.modalContract.find('.modal-title').html();
        // this.modalContract.find('.modal-body').html();
        // this.modalContract.modal('show');
    }

}

class Plan extends React.Component
{
    constructor(props) {
        super(props);
        this.contract = this.contract.bind(this);
    }

    contract(){
        Actions.contract(this.props.store.id);
        Actions.showModalContract(this.props.store.id);
    }

    render (){
        const props = this.props;
        return(
            <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="plan">
                    <div className="head">{ props.store.space }</div>
                    <div className="item db">{ props.store.db } Bases de datos</div>
                    <div className="item correos">{ props.store.mail } Correo</div>
                    <div className="item traffic">{ props.store.transfer }</div>
                    <div className="item ftp">{ props.store.ftp } Cuenta FTP</div>
                    <div className="item alias">{ props.store.alias } Alias de dominio</div>
                    <div className="item soport">{ props.store.support }</div>
                    <div className="item price">${ props.store.price.formatMoney(0, '.', ',') }</div>
                    <div className={`contract pointer ${props.store.recommended?'recommended':''}`} onClick={this.contract}>Contratar</div>
                </div>
            </div>
        )
    }
}

class ModalContract extends Reflux.Component{
    constructor(props) {
        super(props);
        this.store = PlansStore;
    }
    render (){
        const { modalContract } = this.state;
        return(
            <div className={`"modal fade ${modalContract.showModal?'in':''}`} id="modalContract" tabIndex="-1" role="dialog" aria-labelledby="modalContractLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="modalContractLabel">${this.props.title}</h4>
                        </div>
                        <div className="modal-body">
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
/*<div class="modal fade in" id="modalContract" tabindex="-1" role="dialog" aria-labelledby="modalContractLabel" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title" id="modalContractLabel">Modal title</h4>
            </div>
            <div class="modal-body">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>*/
class Plans extends Reflux.Component
{
    constructor(props) {
        super(props);
        this.store = PlansStore;
    }

    render() {
        const { plans } = this.state;
        return (
            <div id="plans" className="row">
                {
                    plans.map(plan => {
                        return <Plan key={plan.id} store={plan} />
                    })
                }
            </div>
        );
    }
}

ReactDOM.render(
    <Plans />,
    document.querySelector('#app-plans')
);
// ReactDOM.render(
//     <ModalContract />,
//     document.querySelector('#app-modal-contract')
// );
